import {
    Activity,
    Language,
    LevelStatsEntry, Page,
    StatsField,
    Vocabulary,
    VocabularyLearningUpdate,
    VocabularyLevelUpdate, VocabularyRange, VocabularyStats, VocabularyWithStats,
} from "@/model/model";
import {AppSnackbarService} from "@/service/AppSnackbarService";
import {UserService} from "@/service/UserService";
import {AbstractService} from "@/service/AbstractService";

export interface Pageable {
    limit: number;
    page: number
}

export interface VocFilter {
    text?: string;
    range?: VocabularyRange;
}

export class VocService extends AbstractService {

    private static INSTANCE = new VocService();

    private languages: Language[] | null = null;

    public static get(): VocService {
        return VocService.INSTANCE;
    }

    public logToAccessLog(text: string) {
        const urlParam = encodeURIComponent(text);
        this.getUserId().then(userId =>
            this.fetchAuthorized(`/users/${userId}/log?text=${urlParam}`)
            .then(() => { true; })
        );
    }

    public async getUserId(): Promise<string> {
        const uuid = (await UserService.get().getUser())?.uuid;
        if (!uuid) {
            throw new Error("not logged in");
        }
        return uuid;
    }

    public async getLanguage(langUuid: string): Promise<Language | undefined> {
        if (this.languages == null) {
            this.languages = await this.getLanguages();
        }
        return (this.languages || []).find(lang => lang.uuid == langUuid);
    }

    public async getLanguages(): Promise<Array<Language>> {
        try {
            const response = await this.fetchAuthorized(`/users/${await this.getUserId()}/languagepairs`);
            const languages = await response.json();
            this.languages = languages;
            return languages;
        } catch (err) {
            AppSnackbarService.get().show("Fehler beim Laden der Sprachen: " + (err as any).message, 'error');
            throw err;
        }
    }

    public async getNextLearnings(langPair: string, vocabRange?: VocabularyRange): Promise<Vocabulary[]> {
        try {
            const response = await this.fetchAuthorized(`/users/${await this.getUserId()}/languagepairs/${langPair}/vocabulary/next-learnings?` + this.getRangeQueryPart(vocabRange));
            return await response.json();
        } catch (err) {
            AppSnackbarService.get().show("Fehler beim Laden der Vokabeln: " + (err as any).message, 'error');
            throw err;
        }
    }

    public async getNewVocabularyCount(langPair: string, vocabRange?: VocabularyRange): Promise<number> {
        const response = await this.fetchAuthorized(`/users/${await this.getUserId()}/languagepairs/${langPair}/vocabulary/new?${this.getRangeQueryPart(vocabRange)}`);
        return (await response.json()).count;
        // hier werden Fehler einfach ignoriert
    }

    public async getNextQuiz(langPair: string, vocabRange?: VocabularyRange, limit = 25) {
        try {
            const response = await this.fetchAuthorized(`/users/${await this.getUserId()}/languagepairs/${langPair}/vocabulary/next-quiz?limit=${limit}&` + this.getRangeQueryPart(vocabRange));
            return await response.json();
        } catch (err) {
            AppSnackbarService.get().show("Fehler beim Laden der Vokabeln: " + (err as any).message, 'error');
            throw err;
        }
    }

    public async increaseLearningCounter(vocUuid: string): Promise<void> {
        try {
            await this.fetchAuthorized(`/users/${await this.getUserId()}/vocabulary/${vocUuid}/stats/learnings`, {
                body: JSON.stringify({
                    vocabulary_uuid: vocUuid
                } as VocabularyLearningUpdate),
                method: 'POST',
                headers: {
                    "content-type": "application/json"
                }
            });
        } catch (err) {
            AppSnackbarService.get().show("Fehler beim Speichern des Lernstatus", 'error', 2000);
            throw err;
        }
    }

    public async modifyLevel(vocUuid: string, levelDirection: -1 | 0 | 1, language_number: 1 | 2, userAnswer?: string, cleanedAnswer?: string, cleanedTranslation?: string, allAnswersAreValid?: boolean) {
        try {
            await this.fetchAuthorized(`/users/${await this.getUserId()}/vocabulary/${vocUuid}/quizstatus`, {
                body: JSON.stringify({
                    vocabulary_uuid: vocUuid,
                    level_direction: levelDirection,
                    language_number: language_number,
                    user_answer: userAnswer,
                    cleaned_user_answer: cleanedAnswer,
                    cleaned_translation: cleanedTranslation,
                    all_answers_are_valid: allAnswersAreValid ? 1 : 0,
                } as VocabularyLevelUpdate),
                method: 'POST',
                headers: {
                    "content-type": "application/json"
                }
            });
        } catch (err) {
            AppSnackbarService.get().show("Fehler beim Speichern des Abfragestatus.", 'error', 2000);
            throw err;
        }
    }

    public async getLevelStats(langPair: string): Promise<Array<LevelStatsEntry>> {
        try {
            const response = await this.fetchAuthorized(`/users/${await this.getUserId()}/languagepairs/${langPair}/vocabulary/levelstats`)
            return await response.json();
        } catch (err) {
            AppSnackbarService.get().show("Fehler beim Laden der Statistik: " + (err as any).message, 'error', 2000);
            throw err;
        }

    }

    public async getActivity(langPair: string): Promise<Array<Activity>> {
        try {
            const response = await this.fetchAuthorized(`/users/${await this.getUserId()}/languagepairs/${langPair}/vocabulary/activity`)
            return await response.json();
        } catch (err) {
            AppSnackbarService.get().show("Fehler beim Laden der Aktivitätsdaten: " + (err as any).message, 'error', 2000);
            throw err;
        }
    }

    public async getVocabulary(langPair: string, vocUuid: string): Promise<Vocabulary> {
        try {
            const response = await this.fetchAuthorized(`/users/${await this.getUserId()}/languagepairs/${langPair}/vocabulary/${vocUuid}`)
            return await response.json();
        } catch (err) {
            AppSnackbarService.get().show("Fehler beim Laden der Vokabel: " + (err as any).message, 'error', 2000);
            throw err;
        }
    }

    public async getVocabularyStats(langPair: string, vocUuid: string): Promise<VocabularyStats | null> {
        try {
            const response = await this.fetchAuthorized(`/users/${await this.getUserId()}/languagepairs/${langPair}/vocabulary/${vocUuid}/stats`)
            if (response.status == 404) {
                return null;
            }
            return await response.json();
        } catch (err) {
            AppSnackbarService.get().show("Fehler beim Laden der Vokabel-Statistik: " + (err as any).message, 'error', 2000);
            throw err;
        }
    }

    public async saveVocabulary(langPair: string, voc: Vocabulary): Promise<Vocabulary> {
        try {
            const response = await this.fetchAuthorized(`/users/${await this.getUserId()}/languagepairs/${langPair}/vocabulary/${voc.uuid}`, {
                body: JSON.stringify(voc),
                method: 'PUT',
                headers: {
                    "content-type": "application/json"
                }
            });
            return await response.json();
        } catch (err) {
            AppSnackbarService.get().show("Fehler beim Speichern der Vokabel.", 'error', 2000);
            throw err;
        }
    }

    public async deleteVocabulary(langPair: string, vocUuid: string): Promise<void> {
        try {
            const response = await this.fetchAuthorized(`/users/${await this.getUserId()}/languagepairs/${langPair}/vocabulary/${vocUuid}`, {
                method: 'DELETE',
            });
        } catch (err) {
            AppSnackbarService.get().show("Fehler beim Löschen der Vokabel.", 'error', 2000);
            throw err;
        }
    }

    public async getVocabularyPaged(langUuid: string, filter: VocFilter, pageable: Pageable): Promise<Page<Vocabulary>> {
        try {
            const response = await this.fetchAuthorized(`/users/${await this.getUserId()}/languagepairs/${langUuid}/vocabulary`
                + `?searchText=${filter.text || ''}`
                +  this.getRangeQueryPart(filter?.range)
                + `&limit=${pageable.limit}&page=${pageable.page}`);
            return await response.json();
        } catch (err) {
            AppSnackbarService.get().show("Fehler beim Laden der Vokabeln: " + (err as any).message, 'error');
            throw err;
        }
    }

    private getRangeQueryPart(vocabRange?: VocabularyRange): string {
        if (vocabRange) {
            return `&rangeFrom=${vocabRange.from?.rangeindex || ''}`
                + `&rangeTo=${vocabRange.to?.rangeindex || ''}`
        } else {
            return '';
        }
    }
}