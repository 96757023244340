
import {Options, Vue} from 'vue-class-component';
import {Language, User, Vocabulary, VocabularyQuizInfo, VocabularyRangeStore, VocabularyWithStats} from "@/model/model";
import VocabPanel from "@/components/VocabPanel.vue";
import {VocService} from "@/service/VocService";
import ProgressBar from "@/components/ProgressBar.vue";
import FinishScreen from "@/components/FinishScreen.vue";
import LearningSuccessScreen from "@/components/LearningSuccessScreen.vue";
import QuizSuccessScreen from "@/components/QuizSuccessScreen.vue";
import FlipCard from "@/components/FlipCard.vue";
import VocabEdit from "@/components/VocabEdit.vue";
import ModalScreen from "@/components/ModalScreen.vue";
import {AppSnackbarService} from "@/service/AppSnackbarService";
import {InjectReactive} from "vue-property-decorator";
import VocabStats from "@/components/VocabStats.vue";
import {CheckResult, InputChecker} from "@/service/InputChecker";
import SpeakButton from "@/components/SpeakButton.vue"; // @ is an alias to /src

const defaultLockState = {
  btnNoIsLocked: true,
  btnMiddleIsLocked: true,
  btnYesIsLocked: true,
}

@Options({
  components: {
    SpeakButton,
    VocabStats,
    FlipCard,
    QuizSuccessScreen,
    LearningSuccessScreen,
    FinishScreen,
    ProgressBar,
    VocabPanel,
    VocabEdit,
    ModalScreen,
  },
})
export default class TextQuizView extends Vue {

  private vocs: (VocabularyWithStats & VocabularyQuizInfo)[] = [];
  private index = 0;
  private submittedIndex = -1;
  private showAnswer = false;
  private askedForLang1 = true;
  private speechUrl1: string | null = null;
  private speechUrl2: string | null = null;
  private showFinishScreen = false;
  private language: Language = {label: '', uuid: '', short_label_lang1: '', short_label_lang2: '', both_directions: 0};
  private openEdit = false;

  private answerCount = 1;
  private answers: string[] = [];
  private checkResult: CheckResult | null = null;

  private lockState = defaultLockState;

  @InjectReactive() debugMode!: string
  @InjectReactive() user!: User | null;

  private timers: number[] = [];

  private stats = {
    succeeded: 0, failed: 0, mixedState: 0, skipped: 0
  }

  get voc1(): string[] {
    if (this.vocs.length < this.index) {
      return [];
    }
    const textField = this.askedForLang1 ? 'text_lang1' : 'text_lang2';
    return this.vocs[this.index][textField].split(',');
  }

  get voc2(): string[] {
    if (this.vocs.length < this.index) {
      return [];
    }
    const textField = this.askedForLang1 ? 'text_lang2' : 'text_lang1';
    return this.vocs[this.index][textField].split(',');
  }

  next(updateLevel: boolean, levelDirection: -1 | 0 | 1): void {
    if (updateLevel && this.lockState.btnNoIsLocked && levelDirection < 0) {
      return;
    }
    if (updateLevel && this.lockState.btnMiddleIsLocked && levelDirection == 0) {
      return;
    }
    if (updateLevel && this.lockState.btnYesIsLocked && levelDirection > 0) {
      return;
    }
    if (this.showFinishScreen) { // weil man sonst mit Return auf der Status-Seite hier reinkommt
      return;
    }

    VocService.get().logToAccessLog("NextClicked")

    const wrongAnswer = levelDirection == -1;

    if (updateLevel && this.submittedIndex < this.index) {
      //do not wait for the answer
      const currentIndex = this.index;

      let cleanedAnswer = this.checkResult?.cleanedAnswers?.sort().join(", ");
      let cleanedTranslations = this.checkResult?.cleanedTranslations?.sort().join(", ");
      let allAnswersAreValid = this.checkResult?.allAnswersAreValid;
      VocService.get().modifyLevel(this.vocs[this.index].uuid, levelDirection, this.askedForLang1 ? 1 : 2, this.answers.sort().join(','), cleanedAnswer, cleanedTranslations, allAnswersAreValid)
          .then(_ => this.submittedIndex = currentIndex)
    }

    if (this.index < this.vocs.length) {
      if (updateLevel) {
        if (levelDirection > 0) {
          this.stats.succeeded++;
        } else if (levelDirection < 0) {
          this.stats.failed++;
        } else {
          this.stats.mixedState++;
        }
      } else {
        this.stats.skipped++;
      }
    }

    // nicht gewusste vokabeln nochmal am ende anstellen
    if (wrongAnswer) {
      const currentVoc = this.vocs[this.index];
      currentVoc.lastTimeAskedForLang1 = this.askedForLang1;
      const vocCount = this.vocs.filter(v => v.uuid == currentVoc.uuid).length;
      if (vocCount < 3) {
        this.vocs.push(currentVoc);
      }
    }

    if (this.index < this.vocs.length - 1) {

      this.index++;
      this.initScreen();

      // die richtung der sprache bestimmen
      this.initLanguageDirection();
    } else {
      this.showFinishScreen = true;
    }
  }

  private initScreen() {
    this.timers.forEach(timer => clearTimeout(timer));
    this.timers.length = 0;
    this.showAnswer = false;
    this.answerCount = 1;
    this.answers = [];
    this.checkResult = null;
    this.lockState = {...defaultLockState};

    // focus auf die textbox setzen
    this.$nextTick(() => (this.$refs['answerInput0'] as any)[0]?.focus());

  }

  private addAnswerInput(event: any) {
    if (event.key === 'Enter' && event.target.value === '' && this.answerCount == 1) {
      // das hier passiert wenn man die vorherigen Vokabel mit Return auf dem Button bestätigt hat.
      return;
    }

    if (event.key === 'Enter' && event.target.value === '') {
      this.checkAnswers();
      return;
    }

    this.answerCount++;
    const newIndex = this.answerCount - 1;
    this.$nextTick(() => {
      try {
        (this.$refs['answerInput' + newIndex] as any)[0].focus();
      } catch (err) {
        setTimeout(() => (this.$refs['answerInput' + newIndex] as any)[0].focus(), 200);
      }
    });
  }

  private showDebugScreen() {
    alert(JSON.stringify(this.checkResult));
  }

  private onKeyUpInButtonPanel(event: KeyboardEvent) {
    if (event.key == 'ArrowRight') {
      if (this.btnNo() == document.activeElement) {
        this.btnMiddle().focus()
      } else if (this.btnMiddle() == document.activeElement) {
        this.btnYes().focus();
      }
    } else if (event.key == 'ArrowLeft') {
      if (this.btnYes() == document.activeElement) {
        this.btnMiddle().focus()
      } else if (this.btnMiddle() == document.activeElement) {
        this.btnNo().focus();
      }
    }
  }

  private btnYes(): HTMLButtonElement {
    return (this.$refs.btnyes as any);
  }

  private btnNo(): HTMLButtonElement {
    return (this.$refs.btnno as any);
  }

  private btnMiddle(): HTMLButtonElement {
    return (this.$refs.btnmiddle as any);
  }

  private checkAnswers() {

    VocService.get().logToAccessLog("CheckAnswersClicked")

    this.timers.push(setTimeout(() => this.lockState.btnNoIsLocked = false, 15000));
    this.timers.push(setTimeout(() => this.lockState.btnMiddleIsLocked = false, 8000));
    this.timers.push(setTimeout(() => this.lockState.btnYesIsLocked = false, 3000));

    const checkResult = new InputChecker().checkInput(this.answers, this.voc2);
    this.showAnswer = true;

    if (checkResult.allAnswersAreValid) { // dann den OK button sofort aktivieren
      this.lockState.btnYesIsLocked = false;
      this.$nextTick(() => this.btnYes().focus());
    } else {
      this.$nextTick(() => this.btnNo().focus());
    }
    this.checkResult = checkResult;
  }

  private initLanguageDirection() {
    const nextVoc = this.vocs[this.index];
    if (!nextVoc) {
      return;
    }

    // wenn die variable vorher schon abgefragt wurde, dann die gleiche richtung verwendung
    if (typeof nextVoc.lastTimeAskedForLang1 === 'boolean') {
      this.askedForLang1 = nextVoc.lastTimeAskedForLang1;
      return;
    }

    if (this.language.both_directions == 0) {
      this.askedForLang1 = true;
      return;
    }
    if (nextVoc.level_lang1 == nextVoc.level_lang2) {
      if (nextVoc.succeeded_lang2 - nextVoc.missed_lang2 == nextVoc.succeeded_lang1 - nextVoc.missed_lang1) {
        this.askedForLang1 = Math.random() < 0.5;
      } else {
        this.askedForLang1 = nextVoc.succeeded_lang2 - nextVoc.missed_lang2 > nextVoc.succeeded_lang1 - nextVoc.missed_lang1;
      }
    } else {
      this.askedForLang1 = nextVoc.level_lang1 < nextVoc.level_lang2;
    }

    if (this.language.uuid == 'de_en') {
      const url = `/users/${this.user?.uuid}/languagepairs/${this.language.uuid}/vocabulary/${this.vocs[this.index].uuid}/speech`;
      this.speechUrl1 = this.askedForLang1 ? null : url;
      this.speechUrl2 = this.askedForLang1 ? url : null;
    }
  }

  async mounted() {
    const langUuid = this.$route.params.langUuid as string
    this.language = await VocService.get().getLanguage(langUuid) || this.language;

    this.vocs = (await VocService.get().getNextQuiz(langUuid, VocabularyRangeStore.load(langUuid)))
        .sort(() => Math.random() - 0.5);
    if (this.vocs?.length == 0) {
      AppSnackbarService.get().show("Noch keine gelernten Vokabeln im Abfragebereich vorhanden.", 'info', 2500);
      this.finish();
    }

    this.index = 0;
    this.initScreen();
    this.initLanguageDirection();
  }

  finish() {
    this.$router.push('./')
  }

  goBack() {
    if (this.index > 0) {
      this.index--;
      this.initScreen();
    }
  }

  editVocab() {
    this.openEdit = true;
    VocService.get().logToAccessLog("VocabEditClicked")
  }

  private onVocabSaved(vocab: Vocabulary): void {
    this.vocs[this.index] = {...this.vocs[this.index], ...vocab};
    this.openEdit = false;
    AppSnackbarService.get().show("Gespeichert", 'success', 1500);
  }

}
